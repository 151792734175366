import { render } from "./EncuestaDeSatisfaccion.vue?vue&type=template&id=62ab74ab"
import script from "./EncuestaDeSatisfaccion.vue?vue&type=script&lang=js"
export * from "./EncuestaDeSatisfaccion.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QSelect,QIcon});
