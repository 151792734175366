<template>
  <div
    class="encuestadesatisfaccion full-width row wrap justify-center items-center content-center q-pa-none q-ma-none"
  >
    <q-card
      class="full-width row wrap justify-center items-center content-start q-pa-none q-ma-none q-px-sm"
      style="min-height: calc(100vh - 83px)"
    >
      <div
        class="col-12 justify-center items-center content-center q-ma-none q-pa-none"
        style=""
      >
        <interstellar-text
          id="encuestadesatisfaccion"
          class="text-h3 q-ma-none q-pa-none q-mt-sm"
        >
          Encuesta de Satisfacción
        </interstellar-text>
        <div class="text-center text-bold">
          Estado del proyecto:
          {{
            selectedRows[0]["Estado del proyecto"]
              ? selectedRows[0]["Estado del proyecto"]
              : "Sin estado"
          }}
        </div>
        <div class="text-center text-bold">
          Correo electrónico:
          {{ to }}
        </div>
        <div class="text-center text-bold">
          Asunto:
          {{ subject }}
        </div>
        <div class="q-pt-md">
          <span
            class="text-subtitle2 text-bold bg-primary text-white q-pa-xs rounded-borders shadow-1"
          >
            CC
          </span>
          <q-select
            v-model="cc"
            :options="filterOptions"
            use-input
            use-chips
            input-debounce="0"
            multiple
            @new-value="createValue"
            @filter="filterFn"
            outlined
            color="primary"
            label-color="primary"
            class="bg-transparent"
          >
            <template v-slot:prepend>
              <q-icon name="edit" color="primary"></q-icon>
            </template>
          </q-select>
        </div>
        <div class="q-pt-md">
          <span
            class="text-subtitle2 text-bold bg-primary text-white q-pa-xs rounded-borders shadow-1"
          >
            BCC
          </span>
          <q-select
            v-model="bcc"
            :options="filterOptions"
            use-input
            use-chips
            input-debounce="0"
            multiple
            @new-value="createValue"
            @filter="filterFn"
            outlined
            color="primary"
            label-color="primary"
            class="bg-transparent"
          >
            <template v-slot:prepend>
              <q-icon name="edit" color="primary"></q-icon>
            </template>
          </q-select>
        </div>
        <div class="q-pt-md">
          <span
            class="text-subtitle2 text-bold bg-primary text-white q-pa-xs rounded-borders shadow-1"
          >
            Vista Previa
          </span>
          <div class="q-pa-none q-ma-none" v-html="htmlBodyView"></div>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
window.moment.locale("es-mx");
import {
  defineAsyncComponent,
  ref,
  watch,
  onMounted,
  // onBeforeUnmount,
} from "vue";

export default {
  name: "encuestadesatisfaccion",
  components: {
    InterstellarText: defineAsyncComponent(() =>
      import("@/components/common/InterstellarText.vue")
    ),
  },
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["getEmailData"],
  setup(props, { emit }) {
    console.log("EncuestaDeSatisfaccion ready");
    // console.log(JSON.stringify(props.selectedRows[0]));
    const to = ref("");
    const subject = ref("");
    const cc = ref([]);
    const bcc = ref([]);
    const stringOptions = [
      "moles@unam.mx",
      "rgarciao@unam.mx",
      "mnavarre@unam.mx",
      "bbarros@unam.mx",
      "byron.fba@unam.mx",
    ];
    const filterOptions = ref(stringOptions);
    // const attachments = ref([]);
    const nombreDeLaEmpresa = ref("");
    const nombreDelUsuario = ref("");
    const solicitudId = ref("");
    const urlSurvey = ref("");
    const imagesUrls = ref([]);
    const inlineImagesBlobs = ref({});
    const inlineImagesBase64 = ref({});
    const htmlBody = ref("");
    const htmlBodyView = ref("");
    const emailData = ref([]);
    async function fillEmailData() {
      to.value = `${
        props.selectedRows[0]["Correo electrónico"]
          ? JSON.parse(
              JSON.stringify(props.selectedRows[0]["Correo electrónico"])
            )
          : "Sin correo"
      }`;
      subject.value =
        "Solicitud de Servicios Tecnológicos UNAM - Encuesta de Satisfación";
      cc.value = [];
      bcc.value = [];
      // const attachments = ref([]);
      nombreDeLaEmpresa.value = `${
        props.selectedRows[0]["Empresa solicitante"]
          ? JSON.parse(
              JSON.stringify(props.selectedRows[0]["Empresa solicitante"])
            )
          : ""
      }`;
      nombreDelUsuario.value = `${
        props.selectedRows[0]["Nombre(s)"]
          ? JSON.parse(JSON.stringify(props.selectedRows[0]["Nombre(s)"])) + " "
          : ""
      }${
        props.selectedRows[0]["Apellidos"]
          ? JSON.parse(JSON.stringify(props.selectedRows[0]["Apellidos"]))
          : ""
      }`;
      solicitudId.value = `${
        props.selectedRows[0]["Número de solicitud"]
          ? JSON.parse(
              JSON.stringify(props.selectedRows[0]["Número de solicitud"])
            )
          : ""
      }`;
      urlSurvey.value = `https://docs.google.com/forms/d/e/1FAIpQLSf3Kqmo12Q5hQrBeF6d8iX_QaOiIS4zq-N0Y6Zi8FsuRN_k3A/viewform?usp=pp_url&entry.2072732348=${nombreDeLaEmpresa.value}&entry.153379399=${nombreDelUsuario.value}&entry.1192088535=${solicitudId.value}`;
      imagesUrls.value = [
        {
          name: "logo2020vinculacion",
          url: "https://i.ibb.co/s3C5vs3/logo-2020-vinculacion.png",
          link: "https://vinculacion.unam.mx",
        },
        {
          name: "surveyLogo",
          url: "https://i.ibb.co/s39SC4C/survey-checklistx.png",
          link: urlSurvey,
        },
        {
          name: "signature",
          url: "https://i.ibb.co/S34nyJZ/Signature-BFBA.jpg",
          link: "https://vinculacion.unam.mx/aviso_privacidad.html",
        },
      ];

      for (let i in imagesUrls.value) {
        // console.log(imagesUrls.value[i]["name"]);
        inlineImagesBlobs.value[imagesUrls.value[i]["name"]] = await fetch(
          imagesUrls.value[i]["url"]
        ).then(function (response) {
          return response.blob();
        });
      }
      for (let item in inlineImagesBlobs.value) {
        // console.log(item);
        inlineImagesBase64.value[item] = await new Promise(
          (resolve, reject) => {
            let reader = new FileReader();
            reader.onerror = () => {
              reader.abort();
              reject("Error reader");
            };
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(inlineImagesBlobs.value[item]);
          }
        ).then((response) => {
          return response;
        });
      }
      // console.log(inlineImagesBlobs.value);
      // console.log(inlineImagesBase64.value);
      // for (let item in inlineImagesBase64.value) {
      //   // console.log(inlineImagesBase64.value[item]);
      //   let arr = inlineImagesBase64.value[item].split(",");
      //   let mime = arr[0].match(/:(.*?);/)[1];
      //   let bstr = atob(arr[1]);
      //   let n = bstr.length;
      //   let u8arr = new Uint8Array(n);
      //   while (n--) {
      //     u8arr[n] = bstr.charCodeAt(n);
      //   }
      //   console.log(new Blob([u8arr], { type: mime }));
      // }
      htmlBody.value = `<!DOCTYPE html><html lang='es-MX' xml:lang'es-MX' xmlns='http://www.w3.org/1999/xhtml'>
        <head>
        <meta charset='utf-8'><meta http-equiv='Content-Type' content='text/html; charset=utf-8' /><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width,initial-scale=1.0'>
        </head>
        <body>
          <table border='0' cellpadding='0' cellspacing='0' width='100%'>
            <tr>
              <td style='padding: 10px 0 10px 0;'>
                <table align='center' border='0' cellpadding='0' cellspacing='0' style='max-width: 800px; border: 1px solid #cccccc; border-collapse: collapse;'>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 10px 0 10px'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td>
                            <table border='0' cellpadding='0' cellspacing='0' style='max-width: 800px;' align='center'>
                              <tr>
                                <td>
                                  <table border='0' cellpadding='0' cellspacing='0' style='min-width: 300px'>
                                    <tr>
                                      <td style='padding: 20px 0 20px 0;'>
                                        <a href='${
                                          imagesUrls.value[0]["link"]
                                        }' target="_blank">
                                          <img src='cid:${
                                            imagesUrls.value[0]["name"]
                                          }' alt='' width='100%' height='auto' style='display: block;' />
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align='center' style='padding: 0px 30px 40px 30px; color: #153643;'>
                      <b style='color: #153643; font-family: Arial, sans-serif; font-size: 20px;'>
                        Dirección de Servicios Tecnológicos
                      </b>
                      <br>
                      <span style='color: #153643; font-family: Arial, sans-serif; font-size: 18px;'>
                        Encuesta de Satisfacción
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px;'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td style='padding: 0 0 0 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; text-align: justify'>
                            <strong>${nombreDelUsuario.value}</strong>
                            ${
                              props.selectedRows[0]["Cargo"]
                                ? "<br>" + props.selectedRows[0]["Cargo"]
                                : ""
                            }
                            ${
                              nombreDeLaEmpresa.value
                                ? "<br>" + nombreDeLaEmpresa.value
                                : ""
                            }
                            <br><br>
                            Agradecemos su acercamiento con la Universidad Nacional Autónoma de México.
                            <br><br>
                            Derivado de su Solicitud de Servicio Tecnológico y con la intención de mejorar el servicio de vinculación entre usted y las entidades universitarias, solicitamos su apoyo para responder la "Encuesta de Satisfacción" accediendo al siguiente enlace:
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td>
                            <table border='0' cellpadding='0' cellspacing='0' style='max-width: 70px;' align='center'>
                              <tr>
                                <td>
                                  <table border='0' cellpadding='0' cellspacing='0' style='min-width: 70px'>
                                    <tr>
                                      <td style='padding: 20px 0 20px 0;'>
                                        <a href='${
                                          imagesUrls.value[1]["link"]
                                        }' target="_blank">
                                          <img src='cid:${
                                            imagesUrls.value[1]["name"]
                                          }' alt='' width='100%' height='auto' style='display: block;' />
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px;'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td style='padding: 0 0 0 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; text-align: center'>
                            <a href='${
                              imagesUrls.value[1]["link"]
                            }'>"Encuesta de Satisfacción"</a>
                            <br><br>
                          </td>
                        </tr>
                        <tr>
                          <td style='padding: 0 0 0 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; text-align: justify'>
                            Su información es confidencial y le tomará sólo tres minutos responder.
                            <br><br>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style='padding: 0 30px 0 30px; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 14px; text-align: justify'>
                      Atentamente
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px;'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td>
                            <table border='0' cellpadding='0' cellspacing='0' style='max-width: 500px'>
                              <tr>
                                <td>
                                  <table border='0' cellpadding='0' cellspacing='0' style='min-width: 140px'>
                                    <tr>
                                      <td style='padding: 20px 0 20px 0;'>
                                        <a href='${
                                          imagesUrls.value[2]["link"]
                                        }' target="_blank">
                                          <img src='cid:${
                                            imagesUrls.value[2]["name"]
                                          }' alt='' width='100%' height='auto' style='display: block;' />
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </body>
        </html>`;
      htmlBodyView.value = `<!DOCTYPE html><html lang='es-MX' xml:lang'es-MX' xmlns='http://www.w3.org/1999/xhtml'>
        <head>
        <meta charset='utf-8'><meta http-equiv='Content-Type' content='text/html; charset=utf-8' /><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='viewport' content='width=device-width,initial-scale=1.0'>
        </head>
        <body>
          <table border='0' cellpadding='0' cellspacing='0' width='100%'>
            <tr>
              <td style='padding: 10px 0 10px 0;'>
                <table align='center' border='0' cellpadding='0' cellspacing='0' style='max-width: 800px; border: 1px solid #cccccc; border-collapse: collapse;'>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 10px 0 10px'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td>
                            <table border='0' cellpadding='0' cellspacing='0' style='max-width: 800px;' align='center'>
                              <tr>
                                <td>
                                  <table border='0' cellpadding='0' cellspacing='0' style='min-width: 300px'>
                                    <tr>
                                      <td style='padding: 20px 0 20px 0;'>
                                        <a href='${
                                          imagesUrls.value[0]["link"]
                                        }' target="_blank">
                                          <img src='${
                                            imagesUrls.value[0]["url"]
                                          }' alt='' width='100%' height='auto' style='display: block;' />
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' align='center' style='padding: 0px 30px 40px 30px; color: #153643;'>
                      <b style='color: #153643; font-family: Arial, sans-serif; font-size: 20px;'>
                        Dirección de Servicios Tecnológicos
                      </b>
                      <br>
                      <span style='color: #153643; font-family: Arial, sans-serif; font-size: 18px;'>
                        Encuesta de Satisfacción
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px;'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td style='padding: 0 0 0 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; text-align: justify'>
                            <strong>${nombreDelUsuario.value}</strong>
                            ${
                              props.selectedRows[0]["Cargo"]
                                ? "<br>" + props.selectedRows[0]["Cargo"]
                                : ""
                            }
                            ${
                              nombreDeLaEmpresa.value
                                ? "<br>" + nombreDeLaEmpresa.value
                                : ""
                            }
                            <br><br>
                            Agradecemos su acercamiento con la Universidad Nacional Autónoma de México.
                            <br><br>
                            Derivado de su Solicitud de Servicio Tecnológico y con la intención de mejorar el servicio de vinculación entre usted y las entidades universitarias, solicitamos su apoyo para responder la "Encuesta de Satisfacción" accediendo al siguiente enlace:
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td>
                            <table border='0' cellpadding='0' cellspacing='0' style='max-width: 70px;' align='center'>
                              <tr>
                                <td>
                                  <table border='0' cellpadding='0' cellspacing='0' style='min-width: 70px'>
                                    <tr>
                                      <td style='padding: 20px 0 20px 0;'>
                                        <a href='${
                                          imagesUrls.value[1]["link"]
                                        }' target="_blank">
                                          <img src='${
                                            imagesUrls.value[1]["url"]
                                          }' alt='' width='100%' height='auto' style='display: block;' />
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px;'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td style='padding: 0 0 0 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; text-align: center'>
                            <a href='${
                              imagesUrls.value[1]["link"]
                            }'>"Encuesta de Satisfacción"</a>
                            <br><br>
                          </td>
                        </tr>
                        <tr>
                          <td style='padding: 0 0 0 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; text-align: justify'>
                            Su información es confidencial y le tomará sólo tres minutos responder.
                            <br><br>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 14px; text-align: justify'>
                      Atentamente
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor='#ffffff' style='padding: 0 30px 0 30px;'>
                      <table border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td>
                            <table border='0' cellpadding='0' cellspacing='0' style='max-width: 500px'>
                              <tr>
                                <td>
                                  <table border='0' cellpadding='0' cellspacing='0' style='min-width: 140px'>
                                    <tr>
                                      <td style='padding: 20px 0 20px 0;'>
                                        <a href='${
                                          imagesUrls.value[2]["link"]
                                        }' target="_blank">
                                          <img src='${
                                            imagesUrls.value[2]["url"]
                                          }' alt='' width='100%' height='auto' style='display: block;' />
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </body>
        </html>`;
      // console.log("end");
    }
    watch(
      [
        to,
        subject,
        cc,
        bcc,
        nombreDeLaEmpresa,
        nombreDelUsuario,
        solicitudId,
        urlSurvey,
        imagesUrls,
        inlineImagesBlobs,
        inlineImagesBase64,
        htmlBody,
        htmlBodyView,
      ],
      () => {
        // console.log("watch");
        emailData.value = {
          to: JSON.parse(JSON.stringify(to.value)),
          cc: JSON.parse(JSON.stringify(cc.value)).join(","),
          bcc: JSON.parse(JSON.stringify(bcc.value)).join(","),
          subject: JSON.parse(JSON.stringify(subject.value)),
          htmlBody: JSON.parse(JSON.stringify(htmlBody.value)),
          inlineImagesBase64: JSON.parse(
            JSON.stringify(inlineImagesBase64.value)
          ),
          dataToAppend: [
            props.selectedRows[0]["Número de solicitud"],
            window
              .moment(
                props.selectedRows[0]["Fecha de Registro"],
                "YYYY-MM-DDTHH:mm:ss.SSSZ"
              )
              .format("YYYY-MM-DD HH:mm:ss.SSS"),
            props.selectedRows[0]["Empresa solicitante"],
            window
              .moment(
                props.selectedRows[0][
                  "Fecha de recepción de la solicitud completa y captura"
                ],
                "YYYY-MM-DDTHH:mm:ss.SSSZ"
              )
              .format("YYYY-MM-DD HH:mm:ss.SSS"),
            window
              .moment(
                props.selectedRows[0][
                  "Fecha y hora del envío de confirmación a la empresa de recibido e inicio de trámite"
                ],
                "YYYY-MM-DDTHH:mm:ss.SSSZ"
              )
              .format("YYYY-MM-DD HH:mm:ss.SSS"),
            props.selectedRows[0]["Nombre(s)"],
            props.selectedRows[0]["Apellidos"],
            props.selectedRows[0]["Correo electrónico"],
            props.selectedRows[0]["Teléfonos (fijo y celular)"],
            props.selectedRows[0][
              "1. Detalles del servicio (por ejemplo: antecedentes y elementos que motivan la solicitud del servicio)"
            ],
            window.moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            window.moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
          ],
        };
        // console.log(emailData.value);
        emit("getEmailData", emailData.value);
      }
    );
    onMounted(() => {
      fillEmailData();
    });
    return {
      // return
      to,
      subject,
      cc,
      bcc,
      filterOptions,
      htmlBody,
      htmlBodyView,
      emailData,
      createValue(val, done) {
        // Calling done(var) when new-value-mode is not set or "add", or done(var, "add") adds "var" content to the model
        // and it resets the input textbox to empty string
        // ----
        // Calling done(var) when new-value-mode is "add-unique", or done(var, "add-unique") adds "var" content to the model
        // only if is not already set
        // and it resets the input textbox to empty string
        // ----
        // Calling done(var) when new-value-mode is "toggle", or done(var, "toggle") toggles the model with "var" content
        // (adds to model if not already in the model, removes from model if already has it)
        // and it resets the input textbox to empty string
        // ----
        // If "var" content is undefined/null, then it doesn't tampers with the model
        // and only resets the input textbox to empty string

        if (val.length > 2) {
          if (!stringOptions.includes(val)) {
            done(val, "add-unique");
          }
        }
      },

      filterFn(val, update) {
        update(() => {
          if (val === "") {
            filterOptions.value = stringOptions;
          } else {
            const needle = val.toLowerCase();
            filterOptions.value = stringOptions.filter(
              (v) => v.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>

<style lang="scss"></style>
